.container {
    display: flex;
    max-width: 75%;
    color: var(--light-color);
    padding: 0.45rem 0.75rem;
    border-radius: 1.15rem;
    line-break: normal;
    font-size: 1rem;
    white-space: normal;
    flex-direction: column;
    box-sizing: border-box;
    gap: .9rem;
    line-height: 1.5rem;

    a {
        color: var(--main-color);
        text-decoration: underline;

        :hover {
            color: var(--main-color-hover);
        }
    }


    p, ol, ul, h1, h2, h3, h4, h5, h6, pre, hr {
        margin: 0;
        margin-block: 0;
    }

    ol, ul {
        // padding-inline-start: 1.2rem;
        li:not(:first-child) {
             margin-top: 0.15rem;
        }
    }

    strong {
        font-weight: 600;
    }

    hr {
        width: 100%;
        border-width: 0.5px;
        border-color: #FFFFFF4A
    }

    code {
        display: inline;
        padding: 0;
    }

    pre {
        code {
            display: block;
            padding: .5rem;
            text-wrap: balance;
        }
    }
}

.user {
    margin-left: auto;
    background-color: var(--dark-accent-color);
}

.assistant {
    margin-right: auto;
    background-color: #FFFFFF41;
}

.preWrap {
    white-space: pre-wrap;
}