.container {
    display: flex;
    max-width: 75%;
    color: var(--light-color);
    padding: 0.5rem 0.5rem;
    border-radius: 0.95rem;
    line-break: normal;
    font-size: 1rem;
    white-space: pre-line;
    margin-right: auto;
    background-color: #FFFFFF41;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.toggleIcon {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .toggleIconLink {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;

        :hover {
            color: var(--main-color-hover);
        }
    }
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.icon {
    color: var(--muted-color);
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinAnim {
    animation: spin 1.5s linear 4;
}