.chatContainer {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 1rem;
}

.footer {
    position: sticky;
    bottom: 0;
    z-index: 2;
}

.messagesContainer {
    $gap: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: center;
    gap: $gap;

    scroll-snap-type: y proximity;
    align-content: end;
    overflow-y: hidden;

    &::after {
        display: block;
        content: "";
        scroll-snap-align: end;
        margin-top: -$gap;
    }

    img {
        max-width: 75%;
        width: 100%;
        height: auto;
        border-radius: 0.95rem;

        &.imgUser {
            margin-right: auto;
        }

        &.imgAssistant {
            margin-left: auto;
        }
    }

    .scrollTarget {
        height: 0;
        padding: 0;
        margin: -$gap 0 0;
    }
}

.separator, .gradientHeader {
    background: var(--dark-color);
    background: linear-gradient(180deg, #1D112800 0%, var(--dark-color) 64%);
    width: 100%;
    height: 1.5rem;
}

.gradientHeader {
    position: fixed;
    top: 0;
    z-index: 1;
    transform: rotate(180deg);
}