.menuButton {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.75rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  color: var(--muted-color);
  transition: color 0.15s;
  transform-origin: center;

  &:hover {
    background-color: transparent;
    color: var(--light-color);
  }

  &:active {
    background-color: transparent;
    color: var(--light-color);
  }

  &:focus {
    background-color: transparent;
    color: var(--light-color);
  }
}

.sendButton {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.75rem;
  flex-direction: column;
  font-size: 1.2rem;
}

.chatBoxContainer {
  display: flex;
  background-color: #FFFFFF2A;
  height: auto;
  border-radius: 1rem 1rem 0 0;
  border: 0.5px solid var(--muted-color);
  border-bottom-color: transparent;
  flex-direction: column;

  position: relative;

  .activeDropzoneOverlay {
    opacity: 0;
    position: absolute;
    box-sizing: border-box;
    background-color: rgba(19, 10, 25, 0.67);
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--muted-color);
    gap: 0.35rem;
    border-radius: 1rem 1rem 0 0;

    .activeDropzoneIcon {
      font-size: 1.2rem;
    }

    .activeDropzoneText {
      font-size: .95rem;
    }
  }

  &.activeDropzone {

    .activeDropzoneOverlay {
      transition: opacity 0.2s ease;
      opacity: 1;
    }
  }
}

.chatBox {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: none;
  border-radius: 1rem 1rem 0 0;
  background-color: transparent;
  color: var(--light-color);
  font-size: 1rem;
  outline: none;
  font-weight: 400;
  overflow-y: hidden;
  resize: none;

  &::placeholder {
    color: var(--muted-color);
  }

    &:focus, &:active {
        outline: none;
      border: none;
      box-shadow: none;
    }
}

.chatBoxWrapper {
  // background-color: var(--dark-color);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  gap: .55rem;
}

.chatBoxRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.hidden {
  display: none;
}

.selectedFiles {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
}

.replyToContainer {
  padding: 1rem;
  width: 100%;
  // background: color(srgb 0.0668 0.0361 0.095 / 0.44);
    background: #1118274A;
  box-sizing: border-box;
  border-radius: 1rem 1rem 0 0;
  color: #FFFFFFBA;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

.replyToCloseButton {
  font-size: 0.75rem;
  cursor: pointer;
  text-align: center;
  color: var(--light-color);
}