.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
  gap: 0.5rem;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.85rem;
  cursor: pointer;

  .icon {
    width: 36px;
    height: 36px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: var(--main-color);
    color: var(--dark-color);
    transition: all 150ms ease-in-out;
  }


  .label {
    font-weight: 500;
    color: var(--dark-color);
  }

  &:hover {
    .icon {
      background-color: var(--main-color-hover);
    }
  }

  &:active {
    .icon {
      transform: scale(0.95);
    }
  }

}

.separator {
  height: .75px;
  background-color: var(--muted-color);
  margin: 0.5rem 0 0.5rem calc(36px + 0.85rem);
}