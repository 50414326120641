.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 20rem;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    /* max-width: 20rem; */
    width: 100%;
}

.formInput {
    box-sizing: border-box;
    font-size: 1rem;
    padding: 0rem 1rem;
    border-radius: 1rem;
    border: 0;
    height: 3.25rem;
    width: 100%;
    line-height: 1.1;
    transition: outline-color 0.15s ease-in-out;
    outline: 2px solid;
    outline-color: var(--muted-color);
}

.invalid {
    outline-color: #D1462F;
    animation: shake 0.5s;
    animation-iteration-count: 1;
}

.formInput::placeholder {
}

.formInput:focus {
    outline-color: #90D7FF;
}

.button {
    padding: 1rem 1rem;
    border-radius: 1rem;
    width: 100%;
}

.title {
    padding: 0;
    font-size: 1.85rem;
    margin: 0;
}

.lead {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(29, 17, 40, 0.72);
}