@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dark-color: #1D1128;
  --light-color: #FFFFFFFA;
  --main-color: #C1FFF2;
  --main-color-hover: #B2EDC5;

  --dark-accent-color: #3083DC;

  --muted-color: rgba(175, 175, 175, 0.69);

  --accent-color: #DB9D47;
  --danger-color: #D1462F;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: var(--dark-color);*/
  background-color: #111827;

}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
  border-radius: 0.5rem;
  transition: all 0.1s;
  text-decoration: none;
  line-height: 1rem;
  outline: none;
  background-color: var(--main-color);
  color: var(--dark-color);
  box-sizing: border-box;

  &.autoSize {
    padding: 1rem 1rem;
    border-radius: 1rem;
    width: 100%;
  }

  &:hover {
    background-color: var(--main-color-hover);
  }

  &:active {
    transform: scale(0.96);
    background-color: var(--main-color-hover);
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;

    &:hover, &:active, &:focus {
      transform: none;
      background-color: var(--main-color);
      color: var(--dark-color);
    }
  }
}


quote {
  font-style: italic;
  color: var(--muted-color);
  font-weight: 500;
  font-size: 0.9rem;

  &::before {
    content: '> ';
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  font-size: .90rem;
  background: rgba(29, 17, 40, 0.5);
  padding: .5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 3px;
}

input[type="text"], input[type="password"], input[type="email"], textarea {
  &.invalid, &[aria-invalid="true"] {
    outline: var(--danger-color) solid 2px;
    animation: shake .5s linear;
    animation-iteration-count: 1;
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
}

.animationShake {
  animation: shake .5s linear;
  animation-iteration-count: 1;
}

@keyframes shake {
  8%, 41% {
    transform: translateX(-4px);
  }
  25%, 58% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-2px);
  }
  92% {
    transform: translateX(2px);
  }
  0%, 100% {
    transform: translateX(0);
  }
}

/*
a {
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: var(--main-color-hover);
  }
}
  */