.container {
  $width: 80px;

  width: $width;
  height: calc($width * 1.4);
  border-radius: 1rem;
  background: var(--dark-color);
  color: var(--light-color);
  font-size: 0.85rem;
  padding: 0.6rem;
  box-sizing: border-box;
  gap: 0.6rem;
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover;
  background-position: center center;

  .filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }

  .extension {
    margin-left: auto;
    margin-right: auto;
    background: var(--accent-color);
    color: var(--dark-color);
    padding: 0.2rem 0.3rem;
    border-radius: 0.4rem;
    font-weight: 500;
  }

  .deleteIcon {
    font-size: 0.6rem;
    box-sizing: border-box;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    background: var(--main-color);
    color: var(--dark-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 0;
    padding-block: 0;
    box-shadow: 0 1px 1px 2px rgba(9, 1, 16, 0.20);
  }
}