.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 10, 25, 0.67);
  z-index: 10;
  box-sizing: border-box;
  box-shadow: 0 3px 6px 3px rgba(9, 1, 16, 0.34);

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    max-width: 700px;
    width: 100%;
    box-sizing: border-box;
    gap: 0.5rem;
  }

  .container {
    background-color: #FFFFFF81;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

  }

  .header {
    display: flex;
    align-items: center;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      color: var(--dark-color);
      background-color: var(--main-color);
      border-radius: 100%;
      transition: background-color 0.15s ease-in-out;
      font-size: 1.0rem;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: var(--main-color-hover);
      }

    }
  }
}







